import React, { useState, useEffect} from 'react';
import { useNavigate, useParams , useLocation} from 'react-router-dom';
import axios from 'axios'
import {toast} from "react-toastify";

const initialFormData = {
  speakersList : [],
  // speakerName : "",
  // speakerDegrees : "",
  // speakerDetails : "",
  photos : null,
  aim : "",
  objective : "",
  gdcLearningOutcomes : "",
  cpdHours : "",
  schedule : null
}

const ScheduleForm = ({eventInfo, scheduleData,  goToPrevPage, saveScheduleData}) => {
    const navigate = useNavigate()
// ********* form Data object
    const [formData, setFormData] = useState(scheduleData)
    const [presignedUrls, setPresignedUrls] = useState([]);
    const [submitting, setSubmitting] = useState(false)
    const [thumbnails, setThumbnails] = useState(formData?.thumbNails)

    // const { eventId, numberOfDays } = useParams();
    // const [speakerName, setSpeakerName] = useState("")
    // const [speakerDegrees, setSpeakerDegrees] = useState("")
    // const [speakerDetails, setSpeakerDetails] = useState("")
    // const [aim, setAim] = useState("")
    // const [objective, setObjective] = useState("")
    // const [gdcLearningOutcomes, setGdcLearningOutcomes]= useState("")
    // const [cpdHours , setCpdHours] = useState("")
    const user = JSON.parse(localStorage.getItem("userData"))
    const token = user?.token;
    // const headers = {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    // };
    let eventId;
    // const [speakersList, setSpeakersList] = useState([
    //   {"name":'', "degrees_awarded":'', "about_speaker":''},
    // ])
    const [speakersList, setSpeakersList] = useState(scheduleData?.speakersList)
    console.log('s List:::::', speakersList)
//          speaker1Name, speaker2Name, speaker3Name,
    const {speakerName, speakerDegrees, speakerDetails, photos, 
      objective, aim, gdcLearningOutcomes, cpdHours, schedule} = formData
  const handleRemoveSpeaker = (speaker, removedIndex)=> {
    setSpeakersList(speakersList.filter((speaker, speakerIndex)=> speakerIndex!==removedIndex))
  }

  const handleSpeakerDataChange = (speaker, speakerIndex, field, value)=> {
    const updatedSpeakerList = [...speakersList]
    updatedSpeakerList[speakerIndex][field] = value
    setSpeakersList(updatedSpeakerList)
  }
  
  // const { numberOfDays } = useParams();
  // const {state} = useLocation()
     
  // const initialSchedule = Array.from({ length: numberOfDays }, (_, day) => ({
  //   day: day + 1,
  //   time_slots: [{ time_from: '', time_to: '', title: '' }],
  // }));

  const handleFilesOnChange = filesObject=> {
    if(filesObject.length===0){
      return
    }
    const notImages = Object.keys(filesObject).some(key=> !filesObject[key].type.startsWith('image/'))
    if(notImages){
      toast.warn('Please select image files only')
      return;
    }
    if(filesObject.length>4){
      toast.warn('Please select 4 images only')
      return
    }
    const imageFiles = Array.from(filesObject)
    // console.log('----selected Images ---:', imageFiles)
    setFormData({...formData, photos:imageFiles})
    displayThumbNails(filesObject)
  }
  const displayThumbNails = (imageObjects) => {
    const imageDisplayUrls = [];
    for (let i = 0; i < imageObjects.length; i++) {
      const file = imageObjects[i];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // console.log(e)
          imageDisplayUrls.push(
            {
              name:file.name,
              url:e.target.result
            });
          if (imageDisplayUrls.length === imageObjects.length) {
            setThumbnails(imageDisplayUrls);
          }
        };
        // console.log('File Reader instanse---:',reader)
        reader.readAsDataURL(file);
      }
    }
  };


  const clearFormData = ()=> {
    setSubmitting(false)
    setFormData(initialFormData)
    setThumbnails()
    setSpeakersList([
      {"title":'',"name":'', "degrees_awarded":'', "about_speaker":''},
    ])
    // console.log('Cleared func Called-------')
  }

  const getPresignedurl = async (photo) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/getSignedurl?contentype=${photo.type}&filename=${photo.name}`,{withCredentials:true});
      // console.log('getPresignedurl',response?.data?.data);
      if (response.data && response.data.data) {
        setPresignedUrls([...presignedUrls, response.data.data]);
        return response.data.data;
      } else {
        console.error('Presigned URL not found in the response.');
        return null;
      }
    
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
    }
  };

  const sendData = async (presignedUrlsArray)=> {
    const photosData = photos.map((photo, index)=> (
      {
        "name":photo.name, 
        "url":presignedUrlsArray[index].url.split('?')[0]}
      ))
    // console.log('photos Data for payload-----:',photosData)
      
      const payload = {
      "id": 1,
      "event_id": eventId,
      // "speaker_details": 
      //   {
      //     "name": speakerName,
      //     "degrees_awarded": speakerDegrees,
      //     "about_speaker":speakerDetails
      //   },
      "speaker_details" : speakersList,
      "schedule": schedule,
      "images" : {
        "Data" : photosData
        },
      "aim": aim.trim(),
      "objectives":objective.trim(),
      "gdc_learning_outcomes":gdcLearningOutcomes.trim(),
      "number_of_cpd_hours": cpdHours
    }
      console.log("payload: ", payload)  
      console.log(schedule);
      // return null
      const url = `${process.env.REACT_APP_API_URL}api/v1/add-event-details`;
      axios.post(url, payload, {withCredentials:true})
      .then((response) => {
          console.log("response from event details: ", response?.data)    
          if (response?.data?.data?.status === 200) {
            // alert("Event Details Added sucessfully!")
            clearFormData()
            navigate(`/events`)
            toast.success('Course created successfully', {delay:500})
          }
      })
      .catch((error) => {
          console.log("Error from event details: ", error)
          setSubmitting(false)

      })  

  }

  const uploadFiles = async () => {
    const presignedUrlsArray = []
    const uploadingPromises = photos?.map(async photo => {
      const  presignedUrlResponse = await getPresignedurl(photo)
      if (!presignedUrlResponse) {
        console.error('Presigned URL is null or undefined.');
        // Handle this case appropriately, e.g., display an error message
        throw new Error('Presigned URL is null or undefined.');
      }
      presignedUrlsArray.push(presignedUrlResponse)
      await axios.put(presignedUrlResponse.url, photo, {withCredentials:true,  'Content-Type': photo.type,})
    });

  try{
    await Promise.all(uploadingPromises)
    console.log('Files uploaded successfully!');
    await sendData(presignedUrlsArray);
  }catch(error){
    setSubmitting(false)
    console.error('Error uploading files:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Response status:', error.response.status);
      console.error('Response data:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Request setup error:', error.message);
    }
  }

};

  // const [schedule, setSchedule] = useState(initialSchedule); -------> moved into useEffect()
  const handleAddNewTimeSlot = (dayIndex) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[dayIndex].time_slots.push({ time_from: '', time_to: '', title: '' });
    // setSchedule(updatedSchedule);
    setFormData({...formData, schedule: updatedSchedule})
  };

  const handleInputChange = (dayIndex, timeSlotIndex, field, value) => {
    const updatedSchedule = [...schedule];
   
    updatedSchedule[dayIndex].time_slots[timeSlotIndex][field] = value;
    // setSchedule(updatedSchedule);
    setFormData({...formData, schedule: updatedSchedule})

  };

  const createNewEvent = ()=> {
    const url = `${process.env.REACT_APP_API_URL}api/v1/create-event`;

          delete eventInfo.numDays
          console.log("payload before req: ", eventInfo)
          axios.post(url, eventInfo, {withCredentials:true})
          .then((response) => {
              // if (response?.data?.data?.status === 200) alert("Event created successfully")    
              console.log("response from event creation: ", response?.data)
              eventId = response?.data?.data?.event_details?.id
              uploadFiles()
              // const timeDifference = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
            // console.log(timeDifference)
           
              // navigate(`/add_schedule/${response?.data?.data?.event_details?.id}/${numDays}`)
          })
          .catch((error) => {
            setSubmitting(false)
              console.log("Error while creating event: ", error)    
          })
  }

  const handleSubmit = (event) => {
      event.preventDefault();
      
      if(!formData.photos){
        toast.warn('please upload photos to submit')
        return
      }

      const InvalidTime = formData.schedule.some(day => 
        day.time_slots.some((slot,index) => {
          if(slot.time_from===slot.time_to){
            toast.error(`Day-${day.day} Slot-${index+1} \n End time should not be same as start time`)
            return true
          }
        })
      )

      if (InvalidTime) {
        return
      }
      setSubmitting(true)
      // uploadFiles()  
      createNewEvent()
};

const deleteTimeSlot = (slotIndex, dayIndex) => {
  // const currentSlots = [...schedule, ]
  const deleteDay = schedule[dayIndex]
  console.log('before',  deleteDay);
  deleteDay.time_slots.splice(slotIndex,1)
  console.log('after',deleteDay)
  schedule[dayIndex] = deleteDay
  setFormData({...formData})
}

const onBack = ()=> {
  console.log('Back - data', formData)
  saveScheduleData({...formData, speakersList:speakersList, thumbNails:thumbnails})
  goToPrevPage()
}
 
 
 
const scheduleTimeSlots = (addDays, currDays) => {
  const scheduledSlots = Array.from({ length: addDays }, (_, index) => ({
    day: currDays + index + 1,
    time_slots: [{ time_from: '', time_to: '', title: '' }],
  }));
  console.log(scheduledSlots)
  return scheduledSlots
}
 
useEffect(()=> {
  const currDays = formData.schedule.length
  const days = Math.abs(eventInfo.numDays - currDays)
  if(eventInfo.numDays > currDays){
    const scheduledSlots = scheduleTimeSlots(days, currDays)
    setFormData({...formData, schedule: [...formData.schedule, ...scheduledSlots]})
  }
  if(eventInfo.numDays < currDays){
    const updatedSchedule = [...formData.schedule]
    updatedSchedule.splice(-days, days)
    setFormData({...formData, schedule:updatedSchedule})
  }
},[])

// useEffect(()=> {
//   setFormData({...formData, schedule: initialSchedule})
// },[])

// if(!user?.token){
//   navigate('/login')
//   return;
// }
// if(user?.role_id !== 1 ){
//   navigate('/')
//   return;
// }


    return (
    <div className="add-event-details-wrapper w-full flex-col p-4 min-h-screen bg-gray-100 font-pop select-none">
        <div className='w-full bg-white h-auto rounded-md shadow-lg p-4 flex flex-col justify-start items-center gap-6'>
            <div className='w-full p-4 justify-center flex items-center'>
                <p className='font-medium text-xl'>Add Course Details</p>
            </div>
            <form onSubmit={handleSubmit} className='w-full gap-4 flex flex-col items-center justify-center'>
              {/* Speakers section - start */}
                  {speakersList.map((speaker, speakerIndex)=> (
                    <div className='w-full' key={speakerIndex}>
                      <div className='flex items-center'>
                        {/* <h1 htmlFor="event_name" className="text-sm font-medium text-gray-800">Speaker: {speakerIndex+1} </h1> */}
                        {/* {speakersList.length>1
                        && <button onClick={()=> handleRemoveSpeaker(speaker, speakerIndex)} className='ml-2 text-xs px-1 rounded-full bg-red-500 text-white'> remove </button>
                        } */}
                        </div>
                      <div className="w-full md:flex gap-2">
                      <div className="w-24">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Title
                    <span className="text-red-500">*</span>
                  </label>
 
                  <select
                    value={speaker.title}
                    onChange={(e) => handleSpeakerDataChange(speaker, speakerIndex, "title", e.target.value)}
                    name="title"
                    id="title"
                    className="invalid:text-gray-400 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none "
                    placeholder="Select Title"
                    required
                  >
                    <option value="" disabled>Title</option>
                    <option value="Mr." className="text-gray-900">Mr.</option>
                    <option value="Dr." className="text-gray-900">Dr.</option>
                    <option value="Ms." className="text-gray-900">Ms.</option>
                    <option value="Mrs." className="text-gray-900">Mrs.</option>
                  </select>
                </div>
                          <div className="md:w-1/4">
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                              Speaker{speakersList.length>1 && `-${speakerIndex+1}`} Name
                                <span className="text-red-500">*</span>
                                </label>
                              <input 
                              type="text" 
                              value={speaker.name} 
                              placeholder="John Doe" 
                              // onChange={(e) => handleSpeakerDataChange(speaker, speakerIndex, "name", e?.target?.value)} 
                              onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                const containsOtherChars = e.target.value.match(/[^a-zA-Z ]/)
                                if(containsOtherChars){
                                  const otherChar = containsOtherChars[0]
                                  e.target.value = e.target.value.replace(otherChar, '')
                                  handleSpeakerDataChange(speaker, speakerIndex, "name", e.target.value)
                                }else{
                                  handleSpeakerDataChange(speaker, speakerIndex, "name", e.target.value)
                                }
                              }}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' 
                              required
                              />
                          </div>
                          <div className="md:w-1/4">
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                                Degrees
                                <span className="text-red-500">*</span>
                                </label>
                              <input type="text" value={speaker.degrees_awarded} placeholder="MSc Oral Pathology, MBA-HCM, ..." 
                              onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                handleSpeakerDataChange(speaker, speakerIndex, "degrees_awarded", e?.target?.value)
                              }} 
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                          </div>
                          <div className="md:w-2/4">
                          <div className='flex justify-between items-center'>
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                                About
                                <span className="text-red-500">*</span>
                                </label>
                              {speakersList.length>1
                                && <button onClick={()=> handleRemoveSpeaker(speaker, speakerIndex)} type='button' className='ml-2 text-xs px-1 rounded-full bg-white-500 text-red-500'> Remove Speaker</button>
                              }
                            </div>
                              <textarea value={speaker.about_speaker} rows={1} placeholder="Information about the speaker" 
                              onChange={(e) => {
                                e.target.value = e.target.value.trimStart()
                                handleSpeakerDataChange(speaker, speakerIndex, "about_speaker", e?.target?.value)
                              }} 
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                          </div>
                      </div>
                    </div>
                    ))}
               
                {speakersList.length<3
                 && <button type='button'
                      onClick={()=> setSpeakersList([...speakersList, {"title":'',"name":'', "degrees_awarded":'', "about_speaker":''}])}
                      className='bg-black text-white px-3 py-2 text-sm rounded-md self-end'>+ Add Speaker</button>
                }
                {/* Speakers Section - end */}
 
              {/* <div className="w-full">
                  <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">Speaker's Name</label>
                      <input type="text" value={speakerName} placeholder="John Doe" onChange={(e) => setFormData({...formData, speakerName: e?.target?.value})} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
              </div>
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">Speaker's Degrees</label>
                    <input type="text" value={speakerDegrees} placeholder="MSc Oral Pathology, MBA-HCM, ..." onChange={(e) =>setFormData({...formData, speakerDegrees: e?.target?.value})} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                </div>
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">About Speaker</label>
                    <textarea value={speakerDetails} placeholder="Information about the speaker" onChange={(e) =>setFormData({...formData, speakerDetails: e?.target?.value})} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                </div> */}
                {/* photos upload */}
                <div className="w-full">
                <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                  Photos
                  <span className="text-red-500">*</span>
                  </label>
                {/* Modified file input Design */}
                <div className='border border-gray-300 rounded-lg'>
                  <input onChange={e=> {handleFilesOnChange(e.target.files)}}
                    type="File"  accept="image/png, image/jpeg, image/webp, image/svg, image/jpg" id="image_upload" multiple placeholder="Upload photos"  
                    className='hidden'
                    // className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full p-2.5 outline-none'
                  />
                  <label 
                    htmlFor='image_upload'
                    className='bg-gray-50  text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400  w-full p-2.5 flex flex-col items-center cursor-pointer '
                  >
                    <svg
                        className="w-6 h-6 text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 21 16"
                      >
                        <path d="M11 16a1 1 0 0 1-1-1v-1h2v1a1 1 0 0 1-1 1Z" />
                        <path d="M17.989 6.124a6.5 6.5 0 0 0-12.495-2.1A5 5 0 0 0 6 14h4V8.414l-.293.293a1 1 0 0 1-1.414-1.414l2-2a1 1 0 0 1 1.414 0l2 2a1 1 0 1 1-1.414 1.414L12 8.414V14h5a4 4 0 0 0 .989-7.876Z" />
                    </svg>
                    <span className='mt-2'>{thumbnails?.length>0? `${thumbnails?.length} file(s)` : 'Click Here to Upload Photos'} </span>
                  </label>
                  {thumbnails?.length>0 && 
                  <div className='self-start flex flex-wrap border-t border-gray-300 p-4 gap-6'>
                      {thumbnails?.map((image)=> <img key={image.name} src={image.url} alt={image.name} className='object-cover w-[100px] h-[100px]  border border-gray-200 rounded-md shadow-md'/>)}
                  </div>}
                  </div>
                </div>
                {/* Aim */}
                 <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                      Aim
                      <span className="text-red-500">*</span>
                      </label>
                    <textarea  value={aim} placeholder="Aim " 
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart()
                      setFormData({...formData, aim:e?.target?.value})
                    }} 
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                </div>
                {/* objectives */}
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                      Objectives
                      <span className="text-red-500">*</span>
                      </label>
                    <textarea value={objective} 
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart()
                      setFormData({...formData, objective: e?.target?.value})
                    }}  
                    placeholder="Objectives" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                </div>
                {/* {/* GDC Learning Outcomes */}
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">GDC Learning Outcomes</label>
                    <textarea value={gdcLearningOutcomes} 
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart()
                      setFormData({...formData, gdcLearningOutcomes: e?.target?.value})
                    }} 
                    placeholder="GDC Learning Outcomes" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' />
                </div>
                {/* number of CPD hours */}
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                      Number of CPD hours
                      <span className="text-red-500">*</span>
                      </label>
                        <input 
                        type="text" 
                        value={cpdHours} 
                        // onChange={(e) =>setFormData({...formData, cpdHours: e?.target?.value})}
                        onChange={(e) => {
                          const containsOtherChars = e.target.value.match(/[^0-9]/)
                          if(containsOtherChars){
                            const otherChar = containsOtherChars[0]
                            e.target.value = e.target.value.replace(otherChar, '')
                            setFormData({...formData, cpdHours: e.target.value})
                          }else{
                            setFormData({...formData, cpdHours: e.target.value})
                          }
                        }}
                        placeholder="Number of CPD hours"  
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' 
                        required
                        />
                </div>
               
            {schedule?.map((day, dayIndex) => (
                <div key={dayIndex} className='flex flex-col gap-3 w-full'>

                    <h2 className='font-medium text-[#E64D26]'>Day {day.day}</h2>
                    {day.time_slots.map((timeSlot, timeSlotIndex) => (
                        <div key={timeSlotIndex} className='flex md:flex-row w-full flex-col justify-between gap-5 items-center'>
                            <p className='self-start -mr-2'>{timeSlotIndex+1}. </p>
                            <div className="md:w-1/4 w-full">

                                <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                                  Start Time
                                  <span className="text-red-500">*</span>
                                  </label>
                                <input type="time" placeholder="Time From" value={timeSlot.time_from} onChange={(e) => handleInputChange(dayIndex, timeSlotIndex, 'time_from', e.target.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                            </div>
                            <div className="md:w-1/4 w-full">
                                <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                                  End Time
                                  <span className="text-red-500">*</span>
                                  </label>
                                <input min={timeSlot.time_from} type="time" placeholder="Time To" value={timeSlot.time_to} onChange={(e) => handleInputChange(dayIndex, timeSlotIndex, 'time_to', e.target.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                            </div>
                            <div className="md:w-1/2 w-full">
                                
                                <div className="flex gap-3 items-center justify-between">
                                <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">
                                  Title
                                  <span className="text-red-500">*</span>
                                </label>

                                {
                                  day.time_slots.length > 1 && 
                                  <button
                                  type="button"
                                    className="outline-none mb-2 cursor-pointer text-red-600 text-sm"
                                    onClick={() => deleteTimeSlot(timeSlotIndex, dayIndex)}
                                  >
                                    Delete
                                  </button>
                                }
             
            </div>
                                <input type="text" maxLength={50} placeholder="Title" value={timeSlot.title} 
                                onChange={(e) => {
                                  e.target.value = e.target.value.trimStart()
                                  handleInputChange(dayIndex, timeSlotIndex, 'title', e.target.value)
                                }} 
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required/>
                            </div>
                        </div>
                    ))}
                    <div className="w-full flex justify-end items-center">
                        <button type='button' className='bg-black text-white w-[35px] h-[35px] rounded-md' onClick={() => handleAddNewTimeSlot(dayIndex)}>+</button>
                    </div>  
                </div>
            ))}
            <div className='flex self-start gap-5'>
            <button type='button' className='font-medium text-secondary border border-secondary rounded-md px-5 py-2' onClick={onBack}>Back</button>
            <button type="submit" className='font-medium self-start outline-none border border-secondary text-white bg-secondary px-5 py-2 rounded-md' disabled={submitting}>
               {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :'Submit'}
            </button>
            </div>
        </form>
        </div>
               
    </div>

  );
};

export default ScheduleForm;



