import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios'
import {toast} from "react-toastify";


const user = JSON.parse(localStorage.getItem("userData"))
const token = user?.token
// const headers = {
//   "Content-Type": "application/json",
//   "Authorization": `Bearer ${token}`,
// };


const EditEvent = () => {
  // const location = useLocation();
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({})
  const { eventId } = useParams();
  const navigate = useNavigate()
  const [eventName, setEventName] = useState(eventData?.event_name ? eventData?.event_name : "")
  const [startDate, setStartDate] = useState(eventData?.event_start_date ? eventData?.event_start_date : "")
  const [endDate, setEndDate] = useState(eventData?.event_end_date ? eventData?.event_end_date : '')
  const [fee, setFee] = useState(eventData?.event_fee ? eventData?.event_fee : "")
  const [maxSeats, setMaxSeats] = useState(eventData?.event_max_seats ? eventData?.event_max_seats : "")
  // const [availSeats, setAvailSeats] = useState(eventData?.event_available_seats ? eventData?.event_available_seats : "")
  const [availSeats, setAvailSeats] = useState(eventData?.event_available_seats)
  const [building, setBuilding] = useState(eventData?.event_place ? eventData?.event_place : "")
  const [street, setStreet] = useState(eventData?.event_street ? eventData?.event_street : '')
  const [city, setCity] = useState(eventData?.event_city ? eventData?.event_city : "")
  const [state, setState] = useState(eventData?.event_state ? eventData?.event_state : '')
  const [country, setCountry] = useState(eventData?.event_country ? eventData?.event_country : "")
  const [speakerName, setSpeakerName] = useState(eventData?.speaker_details?.name ? eventData?.speaker_details?.name : "")
  const [speakerDegrees, setSpeakerDegrees] = useState(eventData?.speaker_details?.qualification ? eventData?.speaker_details?.qualification : "")
  // const [speakerDetails, setSpeakerDetails] = useState(eventData?.speaker_details?.about ? eventData?.speaker_details?.about : "")
  const [speakersList, setSpeakersList] = useState(eventData?.speaker_details)
  const [schedule, setSchedule] = useState(eventData?.schedule ? eventData?.schedule : [])

  const [editEvent, setEditEvent] = useState(false)
  const [editSpeaker, setEditSpeaker] = useState(false)
  const [current, setCurrent] = useState()


  console.log('schedule11', schedule);

  const bookedSeats = Number(eventData?.event_max_seats) - Number(eventData?.event_available_seats)

  const handleDateChange = (key, e) => {
    const value = e?.target?.value
    if (key === "startDate") {
        setStartDate(value)
        console.log(`start date changed to: ${value}`)
    } else {
        setEndDate(value)
          console.log(`end date changed to: ${value}`)
    }
  }

  const submitEditEvent = () => {
    if(maxSeats < bookedSeats){
      toast.warning('Maximum seats should not be less than Booked seats', {delay:500})
      return
    }
    setSubmitting(true)
    const payload = {
      ...eventData,
      "id":1,
      "event_id": eventId,
      "event_name":eventName.trim(),
      "event_start_date": startDate,
      "event_end_date":endDate,
      "event_max_seats": maxSeats,
      "event_available_seats": availSeats + (Number(maxSeats) - Number(current)),
      "event_fee":fee,
      "event_place":building.trim(),
      "event_street":street.trim(),
      "event_city":city.trim(),
      "event_state":state.trim(),
      "event_country":country.trim()
    }
    const url =  `${process.env.REACT_APP_API_URL}api/v1/edit-event`;
    axios.put(url, payload, {withCredentials:true})
    .then((response) => {
        if (response?.data?.data?.status === 200) {
          setSubmitting(false)
        // alert("Event info updated successfully!")
        setAvailSeats(availSeats + (Number(maxSeats) - Number(current)))
        setCurrent(Number(maxSeats))
        toast.success('Event info updated successfully!', {delay:500})
        setEditEvent(false)
       
// setEventData(
//             {...eventData,
//               event_max_seats:maxSeats,
//               event_available_seats: availSeats + (Number(maxSeats) - Number(eventData?.event_max_seats))
//             })
      }
    })
    .catch((error) => {
      setSubmitting(false)
      console.log("Error while updating event info: ", error)
      // alert(`Error while updating event info. Please try again.`)
      toast.error("Error while updating event info. Please try again.")
    })
  }

  const submitEditSpeaker = async() => {
    setSubmitting(true)
    let editStatus
    for (let speaker of speakersList){
      const payload = {
        "id":1,
        "event_id":eventId,
        "speaker_id": speaker.id,
        "title": speaker.title,
        "name": speaker.name.trim(),
        "degrees_awarded": speaker.qualification.trim(),
        "about": speaker.about.trim()
        // "speaker_details": speakersList
      }
      const url =  `${process.env.REACT_APP_API_URL}api/v1/edit-event-speaker-details`;
      await axios.put(url, payload,{withCredentials:true})
      .then((response) => {
        if (response?.data?.data?.status === 200) {
          // alert("Info updated successfully!")
          // setEditSpeaker(false)
          editStatus = true
 
        }
      })
      .catch((error) => {
        setSubmitting(false)
        editStatus = false
        console.log("Error while updating speaker info: ", error)
        alert(`Error while updating speaker info. Please try again.`)
      })
  }
  if(editStatus){
    // alert("Info updated successfully!")
    setSubmitting(false)
    toast.success('Speaker info updated successfully!', {delay:500})
    setEditSpeaker(false)
  }
  }

  const fetchEventDetails = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/get-event-details?event_id=${eventId}`
      )
      .then((response) => {
        
        setEventData(response?.data?.data?.data);
        console.log(response?.data?.data?.data);
        updateValues(response?.data?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error while fetching event by id: ", error);
        setLoading(false);
        alert("Error while fetching event. Please try again.");
      });
  }

  const handleAddNewTimeSlot = (dayIndex) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[dayIndex].time_slots.push({ time_from: '', time_to: '', title: '' });
    setSchedule(updatedSchedule);
  };

  const handleInputChange = (dayIndex, timeSlotIndex, field, value) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[dayIndex].time_slots[timeSlotIndex][field] = value;
    setSchedule(updatedSchedule);
  };

  const handleRemoveSpeaker = (speaker, removedIndex)=> {
    setSpeakersList(speakersList.filter((speaker, speakerIndex)=> speakerIndex!==removedIndex))
  }

  const handleSpeakerDataChange = (speaker, speakerIndex, field, value)=> {
    const updatedSpeakerList = [...speakersList]
    updatedSpeakerList[speakerIndex][field] = value
    setSpeakersList(updatedSpeakerList)
  }

  const updateValues = (data) => {
    setEventName(data?.event_name)
    setStartDate(data?.event_start_date)
    setEndDate(data?.event_end_date)
    setFee(data?.event_fee)
    setMaxSeats(data?.event_max_seats)
    setAvailSeats(data?.event_available_seats)
    setBuilding(data?.event_place)
    setStreet(data?.event_street)
    setCity(data?.event_city)
    setState(data?.event_state)
    setCountry(data?.event_country)
    setCurrent(data?.event_max_seats)
    // setSpeakerName(data?.speaker_details?.name)
    // setSpeakerDegrees(data?.speaker_details?.qualification)
    // setSpeakerDetails(data?.speaker_details?.about)
    setSpeakersList(data?.speaker_details)
    setSchedule(data?.schedule)
  }

  useEffect(() => {
  
      fetchEventDetails()
    
  },[])

  const user = JSON.parse(localStorage.getItem("userData"))

  if(!user?.token){
    navigate('/login')
    return;
  }
  if(user?.role_id !== 1 ){
    navigate('/')
    return;
  }
 
  if (loading) {
    return <div className='flex justify-center w-full mt-20'>
    <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
</div>;
  }
  return (
    <div className="edit-container w-full bg-gray-100 p-4 select-none flex font-pop">
      <div className="w-full bg-white p-4 rounded-md shadow-lg flex flex-col gap-6">
        <div className="event-details-container w-full flex flex-col gap-4">
          <div className='w-full justify-between items-center flex'>
            <p className='font-medium'>Course Details</p>
            <button className='outline-none cursor-pointer text-blue-600' onClick={()=>setEditEvent(!editEvent)}>Edit</button>
          </div>
            <form className='w-full flex justify-start gap-6 flex-col items-start' onSubmit={(e) => {
                  e?.preventDefault()
                  submitEditEvent()
              }}>
              <div className='w-full'>
                <label htmlFor="event_start" className="block mb-2 text-sm text-gray-600">Course Name</label>
                <input type="text" maxLength={50} name="event_name" id="event_name" placeholder="Name of the Event" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={eventName} 
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart()
                  setEventName(e?.target.value)
                }} 
                required 
                disabled={!editEvent} />
            </div>
            {/* <div className='w-full flex sm:flex-row flex-col justify-between gap-5 items-center'>
              <div className='w-full'>
                  <label htmlFor="event_start" className="block mb-2 text-sm text-gray-600">Event Start Date</label>
                <input type="date" name="event_start" id="event_start" min={new Date().toISOString().split('T')[0]} placeholder="YYYY/MM/DD" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={startDate} onChange={(e)=>handleDateChange("startDate", e)} required disabled={!editEvent}/>
              </div>
              <div className='w-full'>
                  <label htmlFor="event_end" className="block mb-2 text-sm text-gray-600">Event End Date</label>
                  <input type="date" name="event_end" id="event_end" min={new Date().toISOString().split('T')[0]} placeholder="DD-MM-YYYY" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={endDate} onChange={(e)=>handleDateChange("endDate", e)} required disabled={!editEvent}/>
              </div>            
            </div> */}
            <div className='w-full'>
              <label htmlFor="event_fee" className="block mb-2 text-sm font-medium text-gray-900">Course Fee</label>
              <input type="text" inputMode="numeric" name="event_fee" id="event_fee" placeholder="£850" className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={fee} 
              onChange={(e) => {
                const containsOtherChars = e.target.value.match(/[^0-9.]/)
                if(containsOtherChars){
                  const otherChar= containsOtherChars[0]
                  setFee(e.target.value.replace(otherChar,''))
                }else{
                  setFee(e?.target?.value)
                }
              }}
              required disabled={!editEvent}/>
            </div>
            <div className='w-full flex justify-between sm:flex-row flex-col gap-5 items-center'>
              <div className='w-full'>
                  <label htmlFor="event_max_seats" className="block mb-2 text-sm font-medium text-gray-900">Maximum Seats  (<span className='text-secondary'>Booked:</span> {bookedSeats},  <span className='text-secondary'>Available:</span> {availSeats})</label>
                  <input type="text" inputMode="numeric" name="event_max_seats" id="event_max_seats" placeholder="20" className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={maxSeats} 
                 onChange={(e) => {
                  const containsOtherChars = e.target.value.match(/[^0-9]/)
                  if(containsOtherChars){
                    const otherChar= containsOtherChars[0]
                    setMaxSeats(e.target.value.replace(otherChar,''))
                  }else{
                    setMaxSeats(e?.target?.value)
                  }
                }}
                  
                  required disabled={!editEvent}/>
              </div>
              {/* <div className='w-full'>
                  <label htmlFor="event_avail_seats" className="block mb-2 text-sm font-medium text-gray-900">Available Seats</label>
                  <input type="number" inputMode="numeric" name="event_avail_seats" id="event_avail_seats" placeholder="10" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={availSeats} onChange={(e)=>setAvailSeats(e?.target.value)} required disabled={!editEvent}/>
              </div> */}
            </div>
            <div className='w-full'>
              <label htmlFor="building" className="block mb-2 text-sm text-gray-600">Building/Flat No</label>
              <input type="text" name="building" id="building" placeholder="Smile Dental Clinic / 201 " className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={building} 
              onChange={(e)=>setBuilding(e?.target.value.trimStart())} 
              required disabled={!editEvent}/>
            </div>
            <div className='w-full'>
              <label htmlFor="street" className="block mb-2 text-sm text-gray-600">Street</label>
              <input type="text" name="street" id="street" placeholder="Unit 5, Longstone Road" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={street} 
              onChange={(e)=>setStreet(e?.target.value.trimStart())} required disabled={!editEvent}/>
            </div>
              <div className='w-full'>
              <label htmlFor="city" className="block mb-2 text-sm text-gray-600">City</label>
              <input type="text" name="city" id="city" placeholder="Manchester M22 5LB" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={city} onChange={(e)=>setCity(e?.target.value.trimStart())} required disabled={!editEvent}/>
            </div>
            <div className='w-full'>
              <label htmlFor="city" className="block mb-2 text-sm text-gray-600">State</label>
              <input type="text" name="city" id="city" placeholder="Manchester" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={state} onChange={(e)=>setState(e?.target.value.trimStart())} required disabled={!editEvent}/>
            </div>
            <div className='w-full'>
              <label htmlFor="country" className="block mb-2 text-sm text-gray-600">Country</label>
              <input type="text" name="country" id="country" placeholder="United Kingdom" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none" value={country} onChange={(e)=>setCountry(e?.target.value.trimStart())} required disabled={!editEvent}/>
            </div>
           {editEvent && <div className="w-full flex justify-center items-center gap-5">
              <button className='w-[120px] h-[40px] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white' onClick={()=>{setEditEvent(false)}}>Cancel</button>
              <button disabled={submitting} type='submit' className='outline-none border-none text-white bg-[#E64D26] w-[120px] md:w-[120px] h-[40px] rounded-md md:text-sm text-xs'>
              {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :`Submit`}</button>
            </div>}
          </form>
          <div></div>  
          </div>
          
        <div className="speaker-details-container  w-full flex flex-col gap-4">
          <div className='w-full justify-between items-center flex'>
              <p className='font-medium'>Speaker Details</p>
              <button className='outline-none cursor-pointer text-blue-600' onClick={()=>setEditSpeaker(!editSpeaker)}>Edit</button>
          </div>   
          <form className='w-full gap-4 flex flex-col items-center justify-center' onSubmit={(e) => {
                  e?.preventDefault()
                  submitEditSpeaker()
              }}>
                {/* Speakers section - start */}
                {speakersList?.map((speaker, speakerIndex)=> (
                    <div className='w-full' key={speakerIndex}>
                      <div className='flex items-center'>
                        {/* <h1 htmlFor="event_name" className="text-sm font-medium text-gray-800">Speaker: {speakerIndex+1} </h1> */}
                        {/* {speakersList.length>1
                        && <button onClick={()=> handleRemoveSpeaker(speaker, speakerIndex)} className='ml-2 text-xs px-1 rounded-full bg-red-500 text-white'> remove </button>
                        } */}
                        </div>
                      <div className="w-full md:flex gap-2">
                      <div className="w-24">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Title
                  </label>
 
                  <select
                    value={speaker.title}
                    onChange={(e) => handleSpeakerDataChange(speaker, speakerIndex, "title", e.target.value)}
                    name="title"
                    id="title"
                    className="invalid:text-gray-400 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none "
                    placeholder="Select Title"
                    required
                    disabled={!editSpeaker}
                  >
                    <option value="" disabled>Title</option>
                    <option value="Mr." className="text-gray-900">Mr.</option>
                    <option value="Dr." className="text-gray-900">Dr.</option>
                    <option value="Ms." className="text-gray-900">Ms.</option>
                    <option value="Mrs." className="text-gray-900">Mrs.</option>
                  </select>
                </div>
                          <div className="md:w-1/4">
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">Speaker{speakersList.length>1 && `-${speakerIndex+1}`} Name</label>
                              <input type="text" value={speaker.name} placeholder="John Doe"
                              //  onChange={(e) => handleSpeakerDataChange(speaker, speakerIndex, "name", e?.target?.value)} 
                              onChange={(e) => {
                                e.target.value = e.target.value.trimStart();
                                const containsOtherChars = e.target.value.match(/[^a-zA-Z ]/)
                                if(containsOtherChars){
                                  const otherChar= containsOtherChars[0]
                                  handleSpeakerDataChange(speaker, speakerIndex, "name", e.target.value.replace(otherChar,''))
                                }else{
                                  handleSpeakerDataChange(speaker, speakerIndex, "name", e.target.value)
                                }
                              }}
                               className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
                          </div>
                          <div className="md:w-1/4">
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">Degrees</label>
                              <input type="text" value={speaker.qualification} placeholder="MSc Oral Pathology, MBA-HCM, ..." 
                              onChange={(e) => {
                                e.target.value = e.target.value.trimStart();
                                handleSpeakerDataChange(speaker, speakerIndex, "qualification", e?.target?.value)
                              }} 
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
                          </div>
                          <div className="md:w-2/4">
                          <div className='flex justify-between items-center'>
                              <label htmlFor="event_name" className="block mb-2 text-sm font-medium text-gray-900">About</label>
                              {/* {(editSpeaker && speakersList.length>1)
                                && <button onClick={()=> handleRemoveSpeaker(speaker, speakerIndex)} type='button' className='ml-2 text-xs px-1 rounded-full bg-white-500 text-red-500'> Remove Speaker-{speakerIndex+1}</button>
                              } */}
                            </div>
                              <textarea value={speaker.about} rows={1} placeholder="Information about the speaker" 
                              onChange={(e) =>{
                                e.target.value = e.target.value.trimStart();
                                handleSpeakerDataChange(speaker, speakerIndex, "about", e?.target?.value)

                              }} 
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
                          </div>
                      </div>
                    </div>
                    ))}
                {/* Speakers Section - end */}
            {/* <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">Speaker's Name</label>
                        <input type="text" value={speakerName} placeholder="John Doe" onChange={(e) =>setSpeakerName(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
                </div>
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">Speaker's Degrees</label>
                    <input type="text" value={speakerDegrees} placeholder="MSc Oral Pathology, MBA-HCM, ..." onChange={(e) =>setSpeakerDegrees(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
                </div>
                <div className="w-full">
                    <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">About Speaker</label>
                    <textarea value={speakerDetails} placeholder="Information about the speaker" onChange={(e) =>setSpeakerDetails(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editSpeaker}/>
            </div> */}
            {editSpeaker && <div className="w-full flex justify-center items-center gap-5">
              <button className='w-[120px] h-[40px] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white' onClick={()=>{setEditSpeaker(false)}}>Cancel</button>
              <button disabled={submitting} type='submit' className='outline-none border-none text-white bg-[#E64D26] w-[120px] md:w-[120px] h-[40px] rounded-md md:text-sm text-xs'>
              {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :`Submit`}</button>
            </div>}
          </form>
        </div>
        <div className="schedule-details-container w-full flex flex-col gap-4">
          <div className='w-full justify-between items-center flex'>
              <p className='font-medium'>Schedule Details</p>
              {/* <button className='outline-none cursor-pointer text-blue-600' onClick={()=>setEditSchedule(!editSchedule)}>Edit</button> */}
          </div> 
          {/* <ScheduleForm schedule={schedule} /> */}
          <div className='w-full flex flex-col gap-6'>
            {Object.keys(schedule).map((day) => {
              return (
                <ScheduleDayCard key={day}  timeSlots={schedule[day]} day={day} event_id={eventId} fetchData={fetchEventDetails}/>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditEvent;

const ScheduleDayCard = ({ day, timeSlots,  event_id, fetchData }) => {
  const [data, setData] = useState(timeSlots)
  const [submitting, setSubmitting] = useState(false)
  // const [editTask, setEditTask] = useState(false)
  // const [timeFrom, setTimeFrom] = useState(data?.time_from ? data?.time_from: "")
  // const [timeTo, setTimeTo] = useState(data?.time_to ? data?.time_to: "")
  // const [title, setTitle] = useState(data?.title? data?.title: "")
  const [newSlots, setNewSlots] = useState([]);

  const submitEventTaskEdit = (taskDetails) => {
    const payload = {
      id: 1,
      event_id: event_id,
      day: day,
      ...taskDetails
    };
    console.log("payload before request: ", payload);
    const url = `${process.env.REACT_APP_API_URL}api/v1/edit-event-details`;
    axios
      .put(url, payload, { withCredentials: true })
      .then((response) => {
        console.log("response from edit task: ", response?.data);
        if (response?.data?.data?.status === 200) {
          // setEditTask(false);
          // alert("Task updated!")
          const dataAfterEdit = data.map(task=> {
            if(task.event_details_id===taskDetails.event_details_id){
              return {...taskDetails, day:day}
            }
            return task
          })
          setData(dataAfterEdit)
          toast.success("Task updated", { delay: 500 });
        }
      })
      .catch((error) => {
        console.log("Error while updating event task: ", error);
        toast.error("Error while updating event task", { delay: 500 });
      });
  };

  const deleteTask = (taskId) => {
    // const confirmTaskDelete = window.confirm(
    //   `Do you want to delete the task "${title}", on day ${data?.day}?`
    // );
    // console.log("user choice: ", confirmTaskDelete);
    // if (confirmTaskDelete) {
      const deletePayload = {
        id: 1,
        event_id: event_id,
        event_details_id: taskId,
      };
      const url = `${process.env.REACT_APP_API_URL}api/v1/delete-event-details`;
      // console.log("headers from delete: ", headers)
      axios
        .delete(url, { data: deletePayload, withCredentials: true })
        .then((response) => {
          if (response?.data?.data?.status === 200) {
            // alert("Task deleted. Please refresh to see the changes.")
            toast.success("Task deleted.", { delay: 500 });
            // fetchData();
            const dataAfterDelete = data.filter(task=> task.event_details_id !== taskId)
            console.log('AFTER_DELETE', dataAfterDelete)
            setData(dataAfterDelete)
          }
        })
        .catch((error) => {
          console.log("error while deleting event_details: ", error);
        });
    // }
  };

  const handleChangeTimeSlot = (slotIndex, field_name, value)=> {
    const currentSlots = [...newSlots]
    currentSlots[slotIndex][field_name] = value
    setNewSlots(currentSlots)
  }
  const submitNewTimeSlots = (e) => {
    e.preventDefault()
    
    const InvalidTime = newSlots.some((slot,index) => {
        if(slot.time_from===slot.time_to){
          toast.error(`${slot.title}, End time should not be same as start time`)
          return true;
        }
      })
    if (InvalidTime){
      return
    }

    setSubmitting(true)
    const payload = {
      "event_id": event_id,
      "schedule": [
          {
              "day": day,
              "time_slots": newSlots
          }
      ]
  }
    console.log('PAYLOAD_newSLOTS', payload)
    const url = `${process.env.REACT_APP_API_URL}api/v1/add-schedule_details`;
    axios
      .post(url, payload, {withCredentials: true })
      .then((response) => {
        setSubmitting(false)
        fetchData()
        toast.success("Time Slots added!")
        console.log("Time Slots added!", response);
      })
      .catch((error) => {
        setSubmitting(false)
        console.log("Error adding timings", error?.response);
      });
  };

  const deleteNewSlot = slotIndex=> {
    const currentSlots = [...newSlots]
    currentSlots.splice(slotIndex,1)
    setNewSlots(currentSlots)
  }

  return (
    <div key={data?.event_details_id} className='w-full flex flex-col gap-6'>
      <div className='w-full justify-between items-center flex'>

        <p className='text-gray-700 text-sm'>Day {day}</p>
        {/* {!editTask && (
          <div className='flex gap-3 items-center justify-center'>
            <button className='outline-none cursor-pointer text-blue-600 text-sm' onClick={() => setEditTask(!editTask)}>Edit</button>
            <button className='outline-none cursor-pointer text-red-600 text-sm' onClick={() => deleteTask()}>Delete</button>
          </div>
        )} */}
      </div> 
      
      {
        data?.map(taskData=>
          <TimeSlot 
          key={taskData.event_details_id} 
          data={taskData} 
          deleteTask={deleteTask}
          submitEventTaskEdit={submitEventTaskEdit}
          tasksCount={data.length}
          />
        )
      }

<form onSubmit={submitNewTimeSlots} className="space-y-5" >
      {
        newSlots?.map((slot, slotIndex)=>
          <div key={slotIndex} className="flex flex-row gap-5 items-center w-full">
            
        <div className="flex justify-between items-center w-full sm:[w-1/3] gap-5">
          <div className="md:w-1/2 w-full">
            <label
              htmlFor="event_name"
              className="block mb-2 text-sm text-gray-600"
            >
              Start Time
            </label>
            <input
              // type="text"
              type="time"
              placeholder="Time From"
              value={slot.time_from}
              onChange={(e) => handleChangeTimeSlot(slotIndex, 'time_from', e?.target?.value)}
              className="border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
              required
            />
          </div>
          <div className="md:w-1/2 w-full">
            <label
              htmlFor="event_name"
              className="block mb-2 text-sm text-gray-600"
            >
              End Time
            </label>
            <input
              // type="text"
              type="time"
              placeholder="Time To"
              min={slot.time_from}
              value={slot.time_to}
              onChange={(e) => handleChangeTimeSlot(slotIndex, 'time_to', e?.target?.value)}
              className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
              required
            />
          </div>
        </div>
        <div className="md:w-2/3 w-full">
          <div className="flex mb-2 justify-between flex-row items-center">
          <label
            htmlFor="event_name"
            className="block text-sm text-gray-600"
          >
            Title
          </label>
      
            <div className="flex gap-3 items-center justify-center">
              <button
              type="button"
                className="outline-none cursor-pointer text-red-600 text-sm"
                onClick={() => deleteNewSlot(slotIndex)}
              >
                Delete
              </button>
            </div>
         
          </div>
          <input
            type="text"
            placeholder="Title"
            value={slot.title}
            maxLength={50}
            onChange={(e) => {
              e.target.value = e.target.value.trimStart();
              handleChangeTimeSlot(slotIndex, 'title', e?.target?.value)
            }}
            className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
            required
          />
        </div>
      </div>
        )
      }
      
        <div className="flex flex-row items-center ">
          {newSlots.length>0 &&
            <div className="flex flex-row gap-5">
              <button
                  onClick={()=> setNewSlots([])}
                  className="border border-secondary text-secondary self-start px-4 py-2 text-sm rounded-md"
                >
                  Cancel
              </button>
            <button
                type="submit"
                disabled={submitting}
                // onClick={()=> {}}
                className="bg-secondary self-start px-4 py-2 text-sm text-white rounded-md"
              >
                 {submitting? 
               <svg aria-hidden="true" className="inline w-5 h-5 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
               :`Submit`}
             
            </button>
          </div>
          }
          <button type='button' className='bg-black ml-auto text-white w-[35px] h-[35px] rounded-md ' 
          onClick={()=> setNewSlots([...newSlots, {"time_from":'', "time_to":'', "title":''}])}>+</button>
      </div>
    </form>

      {/* <form className='flex md:flex-row w-full flex-col justify-between gap-5 items-center' onSubmit={(e) => {
        e?.preventDefault()
        submitEventTaskEdit()
      }}> 
        <div className='flex justify-between items-center w-full sm:[w-1/3] gap-5'>
          <div className="md:w-1/2 w-full">
            <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">Start Time</label>
            <input type="text" placeholder="Time From" value={timeFrom} onChange={(e) => setTimeFrom(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editTask}/>
          </div>
          <div className="md:w-1/2 w-full">
            <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">End Time</label>
            <input type="text" placeholder="Time To" value={timeTo} onChange={(e) => setTimeTo(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editTask}/>
          </div>
        </div>
        <div className="md:w-2/3 w-full">
            <label htmlFor="event_name" className="block mb-2 text-sm text-gray-600">Title</label>
            <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e?.target?.value)} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none' required disabled={!editTask}/>
        </div>
        {editTask && <div className="w-full flex justify-center items-center gap-5">
            <button className='w-[120px] h-[40px] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white' onClick={()=>{setEditTask(false)}}>Cancel</button>
            <button type='submit' className='outline-none border-none text-white bg-[#E64D26] w-[120px] md:w-[120px] h-[40px] rounded-md md:text-sm text-xs'>Submit</button>
          </div>
        }
      </form> */}
    </div>
  )
}

const TimeSlot = ({data, tasksCount, deleteTask, submitEventTaskEdit})=> {
  const [editTask, setEditTask] = useState(false);
  const [timeFrom, setTimeFrom] = useState(
    data?.time_from ? data?.time_from : ""
  );
  const [timeTo, setTimeTo] = useState(data?.time_to ? data?.time_to : "");
  const [title, setTitle] = useState(data?.title ? data?.title : "");

  const handleSubmitTaskEdit = () => {
    
    if(timeFrom === timeTo){
    toast.error('Start time and End time cannot be same.')
    return;
    }
    setEditTask(false)
    const taskDetails = {
      event_details_id: data?.event_details_id,
      time_from: timeFrom,
      time_to: timeTo,
      title: title.trim(),
    };
    submitEventTaskEdit(taskDetails)
    // data = {...data, time_from:timeFrom, time_to:timeTo, title:title}
   
  };

  const deleteTimeSlot = () => {
    const confirmTaskDelete = window.confirm(
      `Do you want to delete the task "${title}", on day ${data?.day}?`
    );
    console.log("user choice: ", confirmTaskDelete);
    if (confirmTaskDelete) {
      deleteTask(data?.event_details_id)
    }
  };
  return (
    <form key={data?.event_details_id}
        className="flex  w-full flex-col justify-between gap-5 items-center"
        onSubmit={(e) => {
          e?.preventDefault();
          handleSubmitTaskEdit();
        }}
      >
        <div className="flex flex-row gap-5 items-center w-full">
          <div className="flex justify-between items-center w-full sm:[w-1/3] gap-5">
            <div className="md:w-1/2 w-full">
              <label
                htmlFor="event_name"
                className="block mb-2 text-sm text-gray-600"
              >
                Start Time
              </label>
              <input
                // type="text"
                type="time"
                placeholder="Time From"
                value={timeFrom}
                onChange={(e) => setTimeFrom(e?.target?.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
                required
                disabled={!editTask}
              />
            </div>
            <div className="md:w-1/2 w-full">
              <label
                htmlFor="event_name"
                className="block mb-2 text-sm text-gray-600"
              >
                End Time
              </label>
              <input
                // type="text"
                type="time"
                placeholder="Time To"
                value={timeTo}
                min={timeFrom}
                onChange={(e) => setTimeTo(e?.target?.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
                required
                disabled={!editTask}
              />
            </div>
          </div>
          <div className="md:w-2/3 w-full">
            <div className="flex mb-2 justify-between flex-row items-center">
            <label
              htmlFor="event_name"
              className="block text-sm text-gray-600"
            >
              Title
            </label>
            {!editTask && (
              <div className="flex gap-3 items-center justify-center">
                <button
                type="button"
                  className="outline-none cursor-pointer text-blue-600 text-sm"
                  onClick={() => setEditTask(!editTask)}
                >
                  Edit
                </button>
                {tasksCount > 1&&
                  <button
                  type="button"
                  className="outline-none cursor-pointer text-red-600 text-sm"
                    onClick={deleteTimeSlot}
                  >
                    Delete
                  </button>
                }
              </div>
            )}
            </div>
            <input
              type="text"
              placeholder="Title"
              maxLength={50}
              value={title}
              onChange={(e) => setTitle(e?.target?.value.trimStart())}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-400 focus:border-gray-400 block w-full p-2.5 outline-none"
              required
              disabled={!editTask}
            />
          </div>
        </div>
        {/* <div className="w-full flex justify-end items-center">
            <button type='button' className='bg-black text-white w-[35px] h-[35px] rounded-md' onClick={() => handleAddNewTimeSlot(dayIndex)}>+</button>
        </div> */}
        {editTask && (
          <div className="w-full flex items-center gap-5">
            <button
            type="button"
              className="w-[120px] h-[40px] justify-center items-center cursor-pointer hover:bg-gray-900 flex p-2 px-4 md:text-sm text-xs bg-black rounded-md text-white"
              onClick={() => {
                setEditTask(false);
                setTimeFrom(data.time_from)
                setTimeTo(data.time_to)
                setTitle(data.title)
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!editTask}
              className="outline-none border-none text-white bg-[#E64D26] w-[120px] md:w-[120px] h-[40px] rounded-md md:text-sm text-xs"
            >
              Submit
            </button>
          </div>
        )}
      </form>
  )
}

