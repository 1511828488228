import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThankYou from './ThankYou';
import {toast} from 'react-toastify';

let patientId;

const SurgicalGuideThirdStep = ({idFromSection1, formData, comment, saveData, savedData, onBack, patientStatus}) => {

  const [popUp, setPopUp] = useState(false);
  const [fileTypeError, setFileTypeError] = useState("");
  const [submitting, setSubmitting] = useState(false)
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(savedData?.fileType);
   const [scanTakenDate, setScanTakenDate] = useState(savedData?.scanDate);
   const [file, setFile] = useState(savedData?.file);
  const fileInputRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
    const filesList = [
        {
          id: 1,
          name: "Implant Plan",
        },
        {
          id: 2,
          name: "Surgical Guide",
        },
    
        {
          id: 3,
          name: "DICOM File",
        },
    
        {
          id: 4,
          name: "STL File",
        },
      ];

    // const getPresignedurl = () => {
    //   axios.get(`${process.env.REACT_APP_API_URL}api/v1/getSignedurl?contentype=application/zip`, {withCredentials:true})
    //   .then(response => {
    //     console.log('getPresignedurl',response);
    //     if (response?.status === 200) {
    //       setPresignedUrl(response?.data?.data);
    //     } else {
    //       console.error('Error fetching presigned URL');
    //     }
    //   })
    //   .catch(error => {
    //     console.error('Network error:', error);
    //     return Promise.reject('Error fetching presigned URL');
    //   });
    // }

    const getPresignedurl = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/getSignedurl?contenttype=${file.type}&filename=${file.name}`,{withCredentials:true});
        console.log('getPresignedurl',response?.data?.data);
        if (response.data && response.data.data) {
          setPresignedUrl(response.data.data);
          return response.data.data;
        } else {
          console.error('Presigned URL not found in the response.');
          return null;
        }
      
      } catch (error) {
        console.error('Error fetching presigned URL:', error);
      }
    };

    const sendData = async (fileUrl) => {
      try{
        const additionalData = {
          // patient_id : idFromSection1,
          patient_id: patientId,
          status_id: formData.patient_status,
          file_type: selectedFileType,
          file_url: fileUrl.split('?')[0],
          scan_taken_date: scanTakenDate,
          uploaded_by: user?.id,
        };
        axios
        .post(`${process.env.REACT_APP_API_URL}api/v1/addfilestopatientrecord`, additionalData, {withCredentials:true} )
        // navigate("/referrals")
        
        console.log('Additional data sent successfully!');
      } catch (error) {
        console.error('Error sending additional data:', error);
        setFileTypeError(error?.response?.data?.data?.message)
      }
    };

    const uploadFile = async () => {
      try {
        // setSubmitting(true);
        // setUploading(true);
        const presignedUrlResponse = await getPresignedurl();
        if (!presignedUrlResponse) {
          console.error('Presigned URL is null or undefined.');
          // Handle this case appropriately, e.g., display an error message
          return;
        }
        await axios.put(presignedUrlResponse.url, file, {withCredentials:true,  'Content-Type': file.type})
        console.log('File uploaded successfully!');
        console.log('filetype', file.type);
        await sendData(presignedUrlResponse.url);
        setUploadSuccess(true);
        setPopUp(true);
      }catch(error){
        console.error('Error uploading file:', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error('Response status:', error.response.status);
          console.error('Response data:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Request setup error:', error.message);
        }
      }finally {
        setSubmitting(false);
        // setUploading(false);
      }
  };

  const createPatientRecord = ()=> {
    const url = `${process.env.REACT_APP_API_URL}api/v1/createpatientrecordvtwo`
    axios.post(url, formData, {withCredentials:true})
      .then(response=> {
        console.log('Patient record created:',response)
        if(response?.data?.data?.status===200){
          patientId = response?.data?.data?.result?.id
          comment ? addComment(patientId) : uploadFile()
        }
      })
      .catch(error=> {
        setSubmitting(false)
        console.log('Error while creating patient record',error?.response)
        alert("Error while creating patient record. Please try again");
 
      })
  }
const addComment = ()=> {
  const payload = {
    patient_id: patientId,
    comment: comment,
    created_by: user?.id,
  };
  const url = `${process.env.REACT_APP_API_URL}api/v1/addcomments`
  axios.post(url, payload, {withCredentials:true})
  .then(response=> {
    console.log('Comment Added:', response)
    uploadFile()
  })
  .catch(error=> {
    setSubmitting(false)
    console.log('Error in adding comment:', error?.response)
    alert("Error while adding comment. Please try again");
   
  })
}

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(!file){
      toast.error('Please select a file to upload!')
      return
    }
    // uploadFile();
    setSubmitting(true);
    createPatientRecord();
  };

  const minDate = new Date()
  minDate.setDate(new Date().getDate() - 30)
  const maxDate = new Date()
  maxDate.setFullYear(new Date().getFullYear() + 5)
  
  return (
   <>
   <div className="my-5 container mx-auto pb-16 px-5 sm:px-12 lg:px-20 font-pop">
    <form onSubmit={handleFormSubmit}>
    <h1 className=" font-medium text-xl">
            Surgical Guide Request
        </h1>
        <div className='flex gap-5 gap-y-5 md:flex-row flex-col py-3'>
        <div className="basis-[50%]">
                <label
                  htmlFor="date"
                  className="text-primary font-medium"
                >
                  Scanning Date
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  value={scanTakenDate}
                  max={maxDate.toISOString().split("T")[0]}
                  min={minDate.toISOString().split("T")[0]}
                  onChange={(e) => setScanTakenDate(e.target.value)}
                  className=" border border-gray-300 invalid:text-gray-400 rounded-lg block w-full p-2 outline-none "
                  placeholder="mm/dd/yyyy"
                  required
                />
              </div>

              <div className="basis-[50%]">
                <label
                  htmlFor="assign_to"
                  className="text-primary font-medium"
                >
                  File type
                  <span className="text-red-500">*</span>
                </label>
                <select
                  name="assign_to"
                  id="assign_to"
                  onChange={(e) => setSelectedFileType(e.target.value)}
                  value={selectedFileType}
                  required
                  className="w-full p-2 rounded-md border-gray-300 invalid:text-gray-400 outline-none border"
                >
                  <option value="" disabled>Select File Type</option>
                  {filesList?.map((file) => (
                    <option
                      key={file?.id}
                      value={file?.name}
                      className="capitalize text-black"
                    >
                      {file?.name}
                    </option>
                  ))}
                </select>
              </div>
        </div>
        <div className="w-full h-full rounded-md flex flex-col justify-center items-center gap-6">
                <label
                  htmlFor="file_upload"
                  className="w-full flex h-full my-3 bg-white flex-col justify-center items-center gap-4 p-5 border rounded-md"
                >
                  <span>
                    <svg
                      className="w-6 h-6 text-primary"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 21 16"
                    >
                      <path d="M11 16a1 1 0 0 1-1-1v-1h2v1a1 1 0 0 1-1 1Z" />
                      <path d="M17.989 6.124a6.5 6.5 0 0 0-12.495-2.1A5 5 0 0 0 6 14h4V8.414l-.293.293a1 1 0 0 1-1.414-1.414l2-2a1 1 0 0 1 1.414 0l2 2a1 1 0 1 1-1.414 1.414L12 8.414V14h5a4 4 0 0 0 .989-7.876Z" />
                    </svg>
                  </span>

                  <span className='text-primary font-medium'>{submitting ? 'Uploading...' : uploadSuccess ? 'Uploaded' : 'Click Here to Upload File'}</span>
                  {file && (
          <p className="mt-2">
            {file.name}
          </p>
        )}
                </label>
                <input
                  // style={{ display: "none" }}
                  className='hidden'
                  type="file"
                  // accept='image/*, video/*, .pdf, .doc, .docx, .ppt, .pptx, .text, .xlsx, .stl,model/stl, .dcm'
                  name="file_upload"
                  ref={fileInputRef}
                  onChange={(e) => { 
                    if(e.target.files.length===0){
                      return
                    }
                    // const file = e.target.files[0];
                    setFile(e.target.files[0])
                    console.log(file)
                    // const allowedTypes = ['image/', 'video/', 'application/pdf', 'application/msword', 
                    //                       'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
                    //                       'application/vnd.ms-powerpoint', 
                    //                       'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    //                       'text/plain', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    //                        'model/stl','application/dicom'];
                    
                    // const notFileTypes = !allowedTypes.some(type => file.type.startsWith(type) || file?.name?.toLowerCase()?.endsWith('.stl'));
                  //   if (notFileTypes) {
                  //     setFileTypeError("file type not supported")
                  //     return;
                  //   }else {
                  //     setFileTypeError('')
                  //   }
                  //   setFile(e.target.files[0])
                   }}
                  id="file_upload"
                />
                   {
            fileTypeError &&
            <p className='text-red-500'>{fileTypeError}</p>
          }
              </div>
              <div className="text-center flex justify-start gap-5 mt-5 py-5">
            <button
            type='button'
            onClick={() =>{ 
              saveData({scanDate:scanTakenDate, fileType:selectedFileType, file:file})  
              onBack()
            }}
              className="px-5 py-2 font-medium text-sm outline-none border text-secondary border-secondary rounded-md"
            >
              Back
            </button>
            <button type='submit' className="bg-secondary text-white text-sm font-medium py-2 px-5 rounded-md"  disabled={submitting}>
            {submitting? 
              <svg aria-hidden="true" className="inline w-4 h-4 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
            : 'Submit'}
            </button>
          </div>
    </form>
   </div>
    {popUp &&
      <ThankYou navigationPage='/referrals' setPopUp={setPopUp} message="Your referral has been submitted."/>
    }
   </>
  )
}

export default SurgicalGuideThirdStep