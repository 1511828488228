import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const AssessmentPage = ({ module }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [answers, setAnswers] = useState({});
  const { module_id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [scoreCount, setScoreCount] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [questionlist, setQuestionlist] = useState(location?.state);
  const user = JSON.parse(localStorage.getItem("userData"));
  const token = user?.token;
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ${token}`,
  //   "ngrok-skip-browser-warning": "skip-browser-warning",
  //   'withCredentials': true
  // };

  const resultData = {
    module_id: questionlist?.module_id,
    student_id: user?.id,
    responses: questionlist?.questions?.map((e) => ({
      q_id: e.q_id,
      selected_answer: answers[e.q_id],
    })),
  };

  const fetchModuleInfoById = () => {
    setIsLoading(true)
    const url = `${process.env.REACT_APP_API_URL}api/v1/get-by-module-id?module_id=${module_id}`;
    axios
      ?.get(url, { withCredentials: true })
      .then((response) => {
        console.log(
          "fetched module: ",
          response?.data?.data?.data?.data?.questions
        );
        setQuestionlist(response?.data?.data?.data?.data);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log("Error while fetching questions: ", error);
      });
  };

  const submittingAnswers = () => {
    
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/student-responses`,
        resultData,
        { withCredentials: true }
      )
      .then((res) => {console.log(res)
        setIsSubmitting(false)
        setModalOpen(true)
      })
      .catch((error) => {
        setIsSubmitting(false)
        console.log(error);
      });
  };

  const handleAnswerChange = (q_Id, selectedChoice) => {
    const selectedQuestion = questionlist.questions.find(
      (q) => q.q_id === q_Id
    );
    const selectedAnswer = selectedQuestion[selectedChoice];
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [q_Id]: selectedAnswer,
    }));
  };

  const submitFeedback = ()=> {
    const payload = {
      "module_id": questionlist?.module_id,
      "rating": feedback,
      "user_id": user?.id
  }
  console.log('Feedback', payload)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/v1/submitrating`,
       payload,
        { withCredentials: true }
      )
      .then((res) => { 
        console.log(res)
        setModalOpen(false)
        navigate(-1)
      })
      .catch((error) => {
        console.log(error);
        setModalOpen(false)
      });
  }

  const calculateScore = () => {
    let userScore = 0;
    questionlist.questions.forEach((question) => {
      const userAnswer = answers[question.q_id];
      const correctAnswer = question.correct_answer;
      if (userAnswer === correctAnswer) {
        userScore += 1;
      }
    });
    return userScore;
  };

  const isAllQuestionsAnswered = questionlist?.questions.every(
    (question) => answers[question.q_id]
  );

  const handleSubmit = () => {
    if (isAllQuestionsAnswered) {
      const result = calculateScore();
      setScoreCount(result);
      setSubmitted(true);
      setIsSubmitting(true);
      // setModalOpen(true);
      submittingAnswers();
    } else {
      alert("Please answer all questions before submitting");
    }
  };

  useEffect(() => {
    if (questionlist !== undefined) {
      console.log("no questions found and the module id is:", module_id);
      fetchModuleInfoById();
    }
  }, []);

  if(!user?.token){
    navigate('/login')
    return;
  }
  if(user?.role_id !== 2 ){
    navigate('/')
    return;
  }

  if(isLoading) 
  return <div className='flex justify-center w-full mt-24'>
          <svg aria-hidden="true" className="inline w-7 h-7 text-primary animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
      </div>

  return (
    <div className=" mx-40 my-4 py-3 font-pop text-sm">
      <div className="border-b pb-3 flex items-end justify-between text-xl font-medium">
      <div>
      <h1 className="text-primary">
        {questionlist?.module_name}
      </h1>
      <p className="text-sm text-gray-500">Course Name: <span className="text-black capitalize">{questionlist?.event?.event_name}</span></p>
      </div>
      <p className="text-sm text-gray-500">No of Questions: <span className="text-black">{questionlist?.questions?.length}</span></p>
      </div>
      <div className=" p-4 my-3 flex  flex-col gap-5">
        {questionlist?.questions?.map((quest, index) => (
          <div
            key={quest?.q_id}
            className="flex flex-col gap-2  justify-center items-start"
          >
            <p className="font-semibold text-lg text-primary">
              {index + 1 + "."} &nbsp;
              <span>{quest.question}</span>
            </p>
            <form className="flex flex-col gap-1">
              {Object?.keys(quest)
                ?.filter((key) => key?.startsWith("choice"))
                ?.map((choicekey, index) => (
                  <div className="flex items-center justify-start w-auto gap-2">
                    <input
                      className="cursor-pointer p-1 checked:accent-rose-500"
                      type="radio"
                      checked={answers[quest?.q_id] === quest?.[choicekey]}
                      onChange={() =>
                        handleAnswerChange(quest?.q_id, choicekey)
                      }
                      disabled={submitted}
                      name={`module ${module?.module_id}_question${quest?.q_id}`}
                      value={choicekey}
                      id={quest?.q_id + choicekey}
                    />
                    <label key={index} htmlFor={quest?.q_id + choicekey}>
                      &nbsp;{quest?.[choicekey]}
                    </label>
                  </div>
                ))}
            </form>
          </div>
        ))}
        <div className="flex justify-center border-t pt-5 gap-4">
        <button
            onClick={() => navigate(-1)}
            className="px-5 py-2 outline outline-1 outline-secondary text-secondary border-0 rounded-md cursor-pointer"
          >
            Back
          </button>
          <button
            className={
              !isAllQuestionsAnswered
                ? "bg-slate-100 text-slate-400 px-4 py-3 outline outline-1  outline-slate-300 border-0 rounded-md"
                : "bg-[#EB7C69] text-white px-4 py-3 outline outline-1 outline-[#EB7C69] border-0 rounded-md  cursor-pointer"
            }
            disabled={!isAllQuestionsAnswered || submitted}
            onClick={handleSubmit}
          >
           {isSubmitting? 
              <svg aria-hidden="true" className="inline w-4 h-4 text-gray-300 animate-spin  fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
             : 'Submit'}
          </button>
         
          
        </div>
        <span className="text-center -mt-2 text-xs text-gray-500">Note: Please answer all the questions to enable the Submit button</span>
        {modalOpen && (
          <>
            <div className="fixed inset-0 bg-black opacity-[0.9] z-40"></div>
            <dialog
              className="md:w-[26rem] lg:w-[30rem] flex items-center justify-center inset-0 fixed z-50 border-black border-solid border-2  rounded-xl"
              open
            >
              <div className="p-6 text-center">
                <h2 className="text-primary text-2xl font-semibold py-2 px-1">Thank You!</h2>
                <p className="text-xl p-1 mb-2">
                  You Scored <span className="font-semibold">{scoreCount}</span>{" "}
                  /{" "}
                  <span className="font-semibold">
                    {questionlist?.questions?.length}
                  </span>
                </p>
 
                <div className="flex flex-col mt-5 items-center justify-center gap-4">
                  <p className="text-primary  text-xl font-semibold p-1">
                    Rate Your Assessment Experience
                  </p>
                  <div className="flex items-center mb-2 justify-center gap-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <div
                        key={star}
                        className="flex flex-col gap-y-2 items-center cursor-pointer"
                        onClick={() => {
                          setFeedback(star);
                          setDisabled(false);
                        }}
                      >
                        <svg
                          className={`
                        ${
                          feedback >= star
                            ? "w-6 h-6 text-[#EFA31D]"
                            : "w-6 h-6  text-[#f0f0f0] stroke-1 stroke-black"
                        }
                         `}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z" />
                        </svg>
                        <span className="text-xs">
                          {star === 1 && "Very Bad"}
                          {star === 2 && "Poor"}
                          {star === 3 && "Medium"}
                          {star === 4 && "Good"}
                          {star === 5 && "Excellent"}
                        </span>
                       
                      </div>
 
                    ))}
                   
                  </div>
                  <span className="text-xs text-gray-500">Note: Choose your ratings to enable the submit button.</span>
                  <button
                    className={
                      disabled
                        ? "bg-slate-200 text-slate-400 px-4 py-2 outline outline-1 outline-slate-300  border-0 rounded-md"
                        : "bg-secondary text-white px-4 py-2 outline outline-1 outline-secondary border-0 rounded-md cursor-pointer"
                    }
                    // onClick={() => {
                    //   setModalOpen(false);
                    //   navigate(-1);
                    // }}
                    onClick={submitFeedback}
                    disabled={disabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </dialog>
          </>
        )}
      </div>
    </div>
  );
};

export default AssessmentPage;
