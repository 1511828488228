import {BrowserRouter as Router, Route, Routes, Outlet} from "react-router-dom";
import './App.css';

import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Register from "./screens/Register";
import ForgotPassword from "./screens/ForgotPassword";
import AssessmentPage from "./screens/AssessmentPage";
import Reports from  "./screens/Reports"
import AdminDashboard from "./screens/AdminDashboard";
import NewQuestion from "./screens/NewQuestionModule"
import ViewAndEdit from "./screens/ViewAndEditQuestion";
import Events from "./screens/Events";
import Contact from "./screens/Contact";
import CreateEvent from "./screens/CreateEvent";
import AddEventDetails from "./screens/AddEventDetails";
import ScheduleForm from "./screens/AddSchedule";
import EventDetails from "./screens/EventDetails";
import EditEvent from "./screens/EditEvent";
import BookingSuccess from "./screens/BookingSuccess";
import PageNotFound from "./screens/PageNotFound";
import CheckoutForm from "./components/Checkout";
import Assessments from "./screens/Assessments";
import CreateCourse from "./screens/CreateCourse";
import Bookings from "./screens/Bookings";
import Referrals from "./screens/Referrals";
import ManageUsers from "./screens/ManageUsers";
import Uploader from "./screens/Uploader";
import PatientDetails from "./screens/PatientDetails";
import OPGReferrals from "./screens/OPGReferrals";
import CBCTReferrals from "./screens/CBCTReferrals";
import ImplantReferrals from "./screens/ImplantReferrals";
import Certificate from './screens/Certificate'
import SurgicalReferrals from "./screens/SurgicalReferrals";
import BookingDetails from "./screens/BookingDetails"
import ResetPassword from "./screens/ResetPassword";
import VerifyRegistration from "./screens/VerifyRegistration"
import { ToastContainer, Slide} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {InactivityTimer} from "./components/InactiviteUserLogout";
import Copyright from "./components/Copyright";


const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <ToastContainer 
        pauseOnHover 
        theme="colored" 
        className="toast-text" 
        closeButton={false} 
        position="top-center" 
        hideProgressBar  
        autoClose={1500} 
        transition={Slide} 
      />
      <Navbar />
      <div className="flex-grow">
        <Outlet />
      </div>
      <Copyright />
    </div>
  );
};

function App() {
  
  return (
    <>
    <ToastContainer className={'toast-text'} closeButton={false} position="top-center" hideProgressBar  autoClose={1500} transition={Slide}/>
    <InactivityTimer children={
    <Router>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/adminDashboard" element={<AdminDashboard />} />
          <Route exact path="/newQuestion/:id" element={<NewQuestion/>}/>
          <Route exact path="/viewAllQuestion/:id" element={<ViewAndEdit />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/create_course" element={<CreateCourse />} />
          <Route exact path="/create_event" element={<CreateEvent />} />
          {/* <Route
            exact
            path="/add_event_details"
            element={<AddEventDetails />}
          /> */}
          <Route
            exact
            path="/add_schedule/:numberOfDays"
            element={<ScheduleForm />}
          />
          <Route
            exact
            path="/event_details/:eventId"
            element={<EventDetails />}
          />
          <Route exact path="/edit_event/:eventId" element={<EditEvent />} />
          <Route exact path="/success" element={<BookingSuccess />} />
          <Route exact path="/checkout" element={<CheckoutForm />} />
          {/* <Route exact path="/courses" element={<Courses />} />
          <Route exact path="/course/:id" element={<Course />} /> */}
          <Route exact path="/referrals" element={<Referrals />} />
          {/* <Route exact path="/guides" element={<Uploader />} /> */}
          <Route exact path="/assessments" element={<Assessments />} />
          <Route exact path="/bookings" element={<Bookings />} />
          <Route exact path="/manage_users" element={<ManageUsers />} />
          <Route exact path="/referrals/OPG-Referrals" element={<OPGReferrals/>}/>
          <Route exact path="/referrals/CBCT-Referrals" element={<CBCTReferrals/>}/>
          <Route exact path="/referrals/Implant-Referrals" element={<ImplantReferrals/>}/>
          <Route exact path="/referrals/surgical-guide-referral" element={<SurgicalReferrals/>}/>
          {/* <Route exact path="/certificate" element={<Certificate/>}/> */}
          <Route exact path="/referrals/patient_record/:id" element={<PatientDetails />} />
          <Route exact path="/booking_details/:eventId" element={<BookingDetails/>}/>
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password/:token" element={<ResetPassword />} />
        {/* <Route exact path="/verify-registration/:userId" element={<VerifyRegistration />} /> */}
        
        <Route
          exact
          path="/assessment/:module_id/:module_name"
          element={<AssessmentPage />}
        />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </Router>
    } />
    </>
  );
}

export default App;
