import React, { useState, useEffect } from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import ThankYou from './../components/ThankYou'
import { toast } from "react-toastify";

const CBCTReferrals = () => {
  const [options, setOptions] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const [status, setStatus] = useState();
  const data = {
    title:"",
    first_name: "",
    last_name: "",
    email_id: "",
    phone_number: "",
    date_of_birth: "",
    maxilla: false,
    mandible: false,
    maxilla_and_mandible: false,
    small_fov: false,
    alerts: "",
    medical_history: "",
    postal_code: "",
    address: "",
    reffered_to: "",
    patient_status: "",
    reffered_by: "",
    type_of_referral: "",
  };
  const [formData, setFormData] = useState(data);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userData"))

  const fetchPatientStatus = ()=> {
    const url = `${process.env.REACT_APP_API_URL}api/v1/getstatuslist/1`
    axios.get(url, { withCredentials: true })
    .then(response=> {
      console.log('response from fetchPatientStatus in CBCT-Referrals::',response)
      setStatus(response?.data?.data?.result)
    })
    .catch(error=> {console.log(error.message)})
  }

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.name === "patient_status") {
      setFormData({
        ...formData,
        patient_status: value === "" ? null : Number(value),
      });
    } else if (e.target.name === "reffered_to") {
      setFormData({
        ...formData,
        reffered_to: value === "" ? null : Number(value),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: value,
        reffered_by: user?.id,
        type_of_referral: 1,
      });
    }
  };

  // const headers = {
  //   "ngrok-skip-browser-warning": "skip-browser-warning",
  // };

  const fetchDoctorNames = () => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/getAllDoctorNames`;
    axios
      .get(url, {withCredentials:true})
      .then((response) => {
        // setOptions(response?.data?.data?.data);
        const doctors = response?.data?.data?.data.filter(doctor=> doctor.id!==user.id)
        setOptions(doctors);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    fetchDoctorNames();
    fetchPatientStatus();
  }, []);

  const handleSubmit = (e) => {

    const siteOfInterestSelected =
      formData.maxilla ||
      formData.mandible ||
      formData.maxilla_and_mandible ||
      formData.small_fov;

    if (!siteOfInterestSelected) {
      alert(
        'Please select at least one checkbox in the "Site of Interest" section.'
      );
      return;
    }
    setSubmitting(true);

    const payload = {
      ...formData,
      postal_code:formData.postal_code.toUpperCase(),
      alerts: formData.alerts.trim(),
      medical_history: formData.medical_history.trim(),
      address: formData.address.trim()
    }

    const url = `${process.env.REACT_APP_API_URL}api/v1/createpatientrecordvtwo`;

    axios
      .post(url, payload, {withCredentials:true})
      .then((response) => {
        console.log("response from submitting CBCT Form", response?.data?.data);
        if (response?.data?.data?.status === 200) {
          // console.log("success");
          setPopUp(true);
          setSubmitting(false)
          // navigate('/referrals')
        }
        console.log("res", formData);
        setFormData({
          title:"",
          first_name: "",
          last_name: "",
          email_id: "",
          phone_number: "",
          date_of_birth: "",
          maxilla: false,
          mandible: false,
          maxilla_and_mandible: false,
          small_fov: false,
          alerts: "",
          medical_history: "",
          postal_code: "",
          address: "",
          reffered_to: "",
          patient_status: "",
          reffered_by: "",
          type_of_referral: "",
        });
      })
      .catch((error) => {
        console.log("Error submitting the form: ", error);
        setSubmitting(false);
        toast.error("Error while submitting the form. Please try again");
      });
  };

  if (user?.token === undefined) {
    navigate("/login")
  }

  return (
    <>
      <div className=" my-5 container mx-auto pb-16 px-5 sm:px-12 lg:px-20 font-pop">
        <h1 className=" font-medium text-2xl py-5 ">
          CBCT Referral
        </h1>
        <form
          onSubmit={(e) => {
            e?.preventDefault()
            handleSubmit()
          }}
        >
          <h1 className=" font-medium text-xl pt-5">
            Patient Details
          </h1>
          <div className="flex gap-8 items-center  gap-y-5 md:flex-row flex-col py-3">
          <div className="w-24">
                  <label
                    htmlFor="title"
                    className="text-primary font-medium "
                  >
                    Title
                    <span className="text-red-500">*</span>
                  </label>


                  <select
                    value={formData?.title}
                    onChange={(e) => handleChange(e)}
                    name="title"
                    id="title"
                    className="w-full p-2 rounded-md border-gray-300 invalid:text-gray-400  outline-none border "
                    required
                  >
                    <option value="" disabled>Title</option>
                    <option value="Mr." className="text-black">Mr.</option>
                    <option value="Ms." className="text-black">Ms.</option>
                    <option value="Mrs." className="text-black">Mrs.</option>
                  </select>
                </div>
            <div className="basis-[40%]">
              <label htmlFor="firstName" className="text-primary font-medium">First Name
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="first_name"
                placeholder="John"
                value={formData.first_name}
                // onChange={handleChange}
                onChange={(e) => {
                  const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                  if(containsOtherChars){
                    const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                    handleChange(e)
                  }else{
                    handleChange(e)
                  }
                }}
                className="w-full border border-gray-300 outline-none p-2 rounded-md"
                required
              />
            </div>
            <div className="basis-[40%]">
              <label htmlFor="lastName" className="text-primary font-medium">Last Name<span className="text-red-500">*</span></label>
              <input
                type="text"
                name="last_name"
                placeholder="Doe"
                value={formData.last_name}
                // onChange={handleChange}
                onChange={(e) => {
                  const containsOtherChars = e.target.value.match(/[^a-zA-Z]/)
                  if(containsOtherChars){
                    const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                    handleChange(e)
                  }else{
                    handleChange(e)
                  }
                }}
                className="w-full border border-gray-300 outline-none p-2 rounded-md"
                required
              />
            </div>
          </div>

          <div className="flex gap-12 gap-y-5  md:flex-row flex-col py-3">
            <div className="basis-[45%]">
              <label htmlFor="email" className="text-primary font-medium">Email Address
              <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email_id"
                placeholder="john.doe@example.com"
                value={formData.email_id}
                onChange={handleChange}
                // onChange={(e) => {
                //   const containsOtherChars = e.target.value.match(/[^a-zA-Z0-9@.]/)
                //   if(containsOtherChars){
                //     const otherChar = containsOtherChars[0]
                    // e.target.value = e.target.value.replace(otherChar, '')
                //     handleChange(e)
                //   }else{
                //     handleChange(e)
                //   }
                // }}
                className="w-full border border-gray-300 outline-none p-2 rounded-md"
                required
              />
            </div>
            <div className="basis-[45%]">
              <label htmlFor="phone" className="text-primary font-medium">Phone Number
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={formData.phone_number}
                name="phone_number"
                placeholder="0131 123 4567"
                // onChange={handleChange}
                minLength={7}
                maxLength={15}
                onChange={(e) => {
                  const containsOtherChars = e.target.value.match(/[^0-9 ]/)
                  if(containsOtherChars){
                    const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                    handleChange(e)
                  }else{
                    handleChange(e)
                  }
                }}
                className="w-full border border-gray-300 outline-none p-2 rounded-md"
                required
              />
            </div>
          </div>

          <div className="flex gap-12 gap-y-5  md:flex-row flex-col py-3">
            <div className="basis-[45%]">
              <label htmlFor="dob" className="text-primary font-medium">Date of Birth
              <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                value={formData.date_of_birth}
                max={new Date().toISOString().split('T')[0]}
                onChange={handleChange}
                name="date_of_birth"
                placeholder="MM/DD/YYYY"
                className="w-full border border-gray-300 invalid:text-gray-400 outline-none p-2 rounded-md"
                required
              />
            </div>
            <div className="basis-[45%]">
              <label htmlFor="postalCode" className="text-primary font-medium">Postal Code
              <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="postal_code"
                placeholder="M5 4SY"
                value={formData.postal_code}
                // onChange={handleChange}
                minLength={4}
                maxLength={10}
                onChange={(e) => {
                  const containsOtherChars = e.target.value.match(/[^0-9a-zA-Z ]/)
                  if(containsOtherChars){
                    const otherChar = containsOtherChars[0]
                    e.target.value = e.target.value.replace(otherChar, '')
                    handleChange(e)
                  }else{
                    handleChange(e)
                  }
                }}
                className="w-full border uppercase border-gray-300 outline-none p-2 rounded-md"
                required
              />
            </div>
          </div>

          <div className="flex gap-12 gap-y-5  md:flex-row flex-col py-3">
          

            <div className="basis-[45%]">
              <label htmlFor="reffered_to" className="text-primary font-medium">Assigned To
              <span className="text-red-500">*</span>
              </label>
              <br />
              <select
                className="w-full p-2 mb-5 invalid:text-gray-400 border-gray-300 outline-none rounded-md border"
                name="reffered_to"
                value={formData.reffered_to}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  select an option
                </option>
                {options?.map((option, index) => {
                  return (
                    <option
                      className="text-black"
                      value={option?.id}
                      key={index}
                    >
                      {option?.title+" "+ option?.first_name + " " + option?.last_name}
                    </option>
                  );
                })}
              </select>
              <div >
              <label htmlFor="patient_status" className="text-primary font-medium">Patient Status
              <span className="text-red-500">*</span>
              </label>
              <br />
              <select
                className="w-full p-2 rounded-md border-gray-300 invalid:text-gray-400  outline-none border"
                value={formData.patient_status}
                name="patient_status"
           
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  select an option
                </option>
                {status?.map((option, index) => {
                  return (
                    <option
                      className="text-black"
                      value={option?.id}
                      key={index}
                    >
                      {option?.status}
                    </option>
                  );
                })}
              </select>
            </div>
            </div>
            <div className="basis-[45%]" >
              <label htmlFor="address" className="text-primary font-medium">Address
              <span className="text-red-500">*</span>
              </label>
              <textarea
              rows={5}
                type="text"
                value={formData.address}
                name="address"
                placeholder="123 Main St, City, State, Country"
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart()
                  handleChange(e)
                }}
                className="w-full border border-gray-300 outline-none p-2 rounded-md"
                required
          />
            </div>
          </div>

          <h1 className=" font-medium text-xl pt-5">
            Site of Interest
          </h1>
          <div className="flex gap-12 gap-y-5 md:flex-row flex-col py-3">
          <div className="basis-[45%]">
              <label htmlFor="medicalHistory" className="text-primary font-medium">Medical History</label>
              <textarea
                rows={5}
                name="medical_history"
                placeholder="Enter your medical history"
                value={formData.medical_history}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart()
                  handleChange(e)
                }}
                className="w-full border p-2 mb-5 border-gray-300 outline-none rounded-md"
              />
               <label className="text-primary font-medium" htmlFor="alerts">Alerts</label>
              <input
                type="text"
                name="alerts"
                placeholder="Enter any alerts"
                value={formData.alerts}
                onChange={(e) => {
                  e.target.value = e.target.value.trimStart()
                  handleChange(e)
                }}
                className="w-full border border-gray-300 outline-none p-2  rounded-md"
              />
            </div>
            <div className="basis-[45%] flex flex-col">
              <label className="text-primary ml-2 font-medium">Please Tick Relevant Boxes
              <span className="text-red-500">*</span>
              </label>
              <label className="inline-flex items-center py-1 pl-2 w-fit">
                <input
                  type="checkbox"
                  name="maxilla"
                  className="mr-2 accent-primary"
                  checked={formData.maxilla}
                  onChange={handleChange}
                />
                Maxilla
              </label>
              <label className="inline-flex items-center py-1 pl-2 w-fit">
                <input
                  type="checkbox"
                  name="mandible"
                  checked={formData.mandible}
                  onChange={handleChange}
                  className="mr-2 py-1 accent-primary"
                />
                Mandible
              </label>
              <label className="inline-flex items-center py-1 pl-2 w-fit">
                <input
                  type="checkbox"
                  name="maxilla_and_mandible"
                  checked={formData.maxilla_and_mandible}
                  onChange={handleChange}
                  className="mr-2 accent-primary"
                />
                Maxilla and Mandible
              </label>
              <label className="inline-flex items-center py-1 pl-2 w-fit">
                <input
                  type="checkbox"
                  name="small_fov"
                  checked={formData.small_fov}
                  onChange={handleChange}
                  className="mr-2 accent-primary"
                />
                Small FoV
              </label>
             
            </div>
        
          </div>
          <div className="text-center flex justify-start gap-5 py-5">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="px-5 py-2 font-medium text-secondary text-sm rounded-md border border-secondary cursor-pointer"
          >
            Back
          </button>
            <button
              className="bg-secondary font-medium text-white py-2 text-sm px-5 rounded-md"
              // onClick={handleSubmit}
              type="submit"
              disabled={submitting}
            >
             {submitting? 
              <svg aria-hidden="true" className="inline w-4 h-4 text-white animate-spin  fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
            : 'Submit'}
            </button>
          </div>
        </form>
      </div>
      {popUp &&
      <ThankYou navigationPage='/referrals' setPopUp={setPopUp} message="Your referral has been submitted."/>
      }
    </>
  );
};

export default CBCTReferrals;
