import React, { useEffect, useState, useRef } from 'react';
import logoBlack from './../assets/logo-black.png';
import { useNavigate, useLocation } from 'react-router-dom';
import cognismilesLogo from './../assets/cognismiles-logo.png'

const Navbar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("userData")));
  const [showOut, setShowOut] = useState(false);
  const [showRefMenu, setShowRefMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showMobNavMenu, setShowMobNavMenu] = useState(false)
  const isAdmin = user?.role_id === 1;

  const refferalMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const navbarRef = useRef(null);



  const handleLogout = () => {
    localStorage?.removeItem("userData");
    navigate("/login");
    window.location.reload(true);
  }

  useEffect(() => {
    if (user?.token !== undefined) {
      setShowOut(true);
    } else {
      setShowOut(false);
    }
  }, [user]);

  useEffect(() => {

    const handleOutsideClick = (event) => {
     
      // if ((refferalMenuRef.current && !refferalMenuRef.current.contains(event.target)) && (userMenuRef.current && !userMenuRef.current.contains(event.target))) {
      //   setShowRefMenu(false);
      //   setShowUserMenu(false);
      // }

      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setShowRefMenu(false);
        setShowUserMenu(false);
        setShowMobNavMenu(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  return (
    <nav  ref={navbarRef} className="bg-white z-10 sticky top-0 border-gray-200 border-b-2 w-full  font-pop select-none tracking-tight">
      <div className="flex flex-wrap items-center justify-between px-5 md:px-6 w-full">
        <div className='flex gap-2 items-center justify-start'>
          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => setShowMobNavMenu(!showMobNavMenu)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6 text-gray-800"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 17 14"
            >
              <path d="M16 2H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 1 1 0 2Zm0 6H1a1 1 0 0 1 0-2h15a1 1 0 0 1 0 2Z"></path>
            </svg>
          </button>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            {/* <img
              src={logoBlack}
              className="w-12 grayscale"
              height="100%"
              width="100%"
              alt=""
            />
            <span
              className={`flex items-center p-2 text-gray-900 rounded-lg group text-xl font-semibold font-pop`}
            >
              Cognismiles
            </span> */}

              <img
              src={cognismilesLogo}
              width="90px"
              alt="Logo"
            />
          </div>
        </div>

        <div className="flex gap-3 items-center justify-center md:hidden">
          <div
            className="relative w-full"
            onClick={() => setShowUserMenu(!showUserMenu)}
          >
            <svg
              className="w-7 h-7 text-gray-800 cursor-pointer"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
            </svg>
            <div
              className={`absolute z-50 right-0 mt-4 z-10 ${
                showUserMenu
                  ? "block opacity-100 transition"
                  : "hidden opacity-0"
              } bg-gray-800 text-white shadow-md w-36 rounded-lg text-sm flex flex-col justify-center`}
              ref={userMenuRef}
            
            >
              {user?.token !== undefined ? (
                <>
                  <p
                    className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                    onClick={() => navigate("/bookings")}
                  >
                    Bookings
                  </p>
                  {user?.role_id !== 1 && (
                    <p
                      className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                      onClick={() => navigate("/reports")}
                    >
                      Reports
                    </p>
                  )}
                  <p
                    className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                    onClick={() => navigate("/contact")}
                  >
                    Support
                  </p>
                  {user?.role_id === 1 && (
                    <p
                      className="p-2 px-3 cursor-pointer  hover:text-blue-400 border-b border-gray-600"
                      onClick={() => navigate("/manage_users")}
                    >
                      Manage Users
                    </p>
                  )}
                  <p
                    className="p-2 px-3 cursor-pointer  hover:text-blue-400"
                    onClick={handleLogout}
                  >
                    Signout
                  </p>
                </>
              ) : (
                <>
                <p
                className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                onClick={() => navigate("/contact")}
              >
                Support
              </p>
                <p
                  className="p-2 px-3 cursor-pointer  hover:text-blue-400"
                  onClick={() => navigate("/login")}
                >
                  Login
                </p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex p-4 items-center md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 gap-5 md:mt-0 md:border-0 md:bg-white">
            <li onClick={() => navigate("/")}>
              <p
                className={`cursor-pointer block py-2 pl-3 pr-4 ${
                  window?.location?.pathname === "/" && "text-blue-700"
                } rounded md:border-0 hover:text-blue-700 md:p-0`}
              >
                Home
              </p>
            </li>
            <li onClick={() => navigate("/assessments")}>
              <p
                className={`cursor-pointer block py-2 pl-3 pr-4 ${
                  window?.location?.pathname?.includes("assessments") &&
                  "text-blue-700"
                } rounded hover:text-blue-700 md:p-0`}
              >
                Assessments
              </p>
            </li>
            <li onClick={() => navigate("/events")}>
              <p
                className={`cursor-pointer block py-2 pl-3 pr-4 ${
                  window?.location?.pathname?.includes("events") &&
                  "text-blue-700"
                } rounded hover:text-blue-700 md:p-0`}
              >
                Courses
              </p>
            </li>
            {/* <li onClick={() => navigate("/guides")}>
              <p
                className={`cursor-pointer block py-2 pl-3 pr-4 ${
                  window?.location?.pathname?.includes("guides") &&
                  "text-blue-700"
                } rounded hover:text-blue-700 md:p-0`}
              >
                Guides
              </p>
            </li> */}
            <li className="relative" onClick={() => navigate("/referrals")}>
              <p
                className={`cursor-pointer block py-2 pl-3 pr-4 ${
                  window?.location?.pathname?.includes("referrals") &&
                  "text-blue-700"
                } rounded hover:text-blue-600 md:p-0`}
              >
                Referrals
              </p>
              {/* <div className={`absolute right-0 mt-2 ${showRefMenu ? "block" : "hidden"} bg-gray-800 text-white shadow-md w-36 rounded-lg text-sm flex flex-col justify-center`} ref={refferalMenuRef}>
                <p className="p-2 cursor-pointer border-b border-gray-600 hover:text-blue-400" onClick={() => navigate("/referrals/treatment")}>Treatment</p>
                <p className="p-2 cursor-pointer hover:text-blue-400" onClick={() => navigate("/referrals/ct_scans")}>CT Scans</p>
              </div> */}
            </li>
            <li
              className="relative"
              onClick={() => setShowUserMenu(!showUserMenu)}
            >
              <svg
                className="w-6 h-6 text-gray-800 cursor-pointer"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              <div
                className={`absolute right-0 mt-4 z-10 ${
                  showUserMenu ? "block" : "hidden"
                } bg-gray-800 text-white shadow-md w-36 rounded-lg text-sm flex flex-col justify-center`}
                ref={userMenuRef}
              >
                {user?.token !== undefined ? (
                  <>
                    <p
                      className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                      onClick={() => navigate("/bookings")}
                    >
                      Bookings
                    </p>
                    {user?.role_id !== 1 && (
                      <p
                        className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                        onClick={() => navigate("/reports")}
                      >
                        Reports
                      </p>
                    )}
                    <p
                      className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                      onClick={() => navigate("/contact")}
                    >
                      Support
                    </p>
                    {user?.role_id === 1 && (
                      <p
                        className="p-2 px-3 cursor-pointer  hover:text-blue-400 border-b border-gray-600"
                        onClick={() => navigate("/manage_users")}
                      >
                        Manage Users
                      </p>
                    )}
                    <p
                      className="p-2 px-3 cursor-pointer  hover:text-blue-400"
                      onClick={handleLogout}
                    >
                      Signout
                    </p>
                  </>
                ) : (
                  <>
                  <p
                  className="p-2 px-3 cursor-pointer border-b border-gray-600 hover:text-blue-400"
                  onClick={() => navigate("/contact")}
                >
                  Support
                </p>
                  <p
                    className="p-2 px-3 cursor-pointer  hover:text-blue-400"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </p>
                  </>
                )}
              </div>
            </li>
          </ul>
        </div>
        {showMobNavMenu && (
          <div className="w-full md:hidden">
            <ul className="font-medium flex flex-col divide-y-1 divide-gray-300 p-4 items-center md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 gap-3 md:mt-0 md:border-0 md:bg-white">
              <li className="w-full" onClick={() => {
                setShowMobNavMenu(false)
                navigate("/")
              }}>
                <p
                  className={`cursor-pointer block py-2 pl-3 pr-4 ${
                    window?.location?.pathname === "/" && "text-blue-700"
                  } rounded md:border-0 hover:text-blue-700 md:p-0`}
                >
                  Home
                </p>
              </li>
              <li className="w-full" onClick={() => {
                setShowMobNavMenu(false)
                navigate("/assessments")
              }}>
                <p
                  className={`cursor-pointer block py-2 pl-3 pr-4 ${
                    window?.location?.pathname?.includes("assessments") &&
                    "text-blue-700"
                  } rounded hover:text-blue-700 md:p-0`}
                >
                  Assessments
                </p>
              </li>
              <li className="w-full" onClick={() => {
                setShowMobNavMenu(false)
                navigate("/events")
              }}>
                <p
                  className={`cursor-pointer block py-2 pl-3 pr-4 ${
                    window?.location?.pathname?.includes("events") &&
                    "text-blue-700"
                  } rounded hover:text-blue-700 md:p-0`}
                >
                  Courses
                </p>
              </li>

              <li
                className="relative w-full"
                onClick={() => {
                  setShowMobNavMenu(false);
                  navigate("/referrals")
                }}
              >
                <p
                  className={`cursor-pointer block py-2 pl-3 pr-4 ${
                    window?.location?.pathname?.includes("referrals") &&
                    "text-blue-700"
                  } rounded hover:text-blue-600 md:p-0`}
                >
                  Referrals
                </p>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
