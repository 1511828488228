import React from 'react';
import logo from '../assets/logo-altumind.png';

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="flex sm:flex-row flex-col max-sm:text-center bg-[#E8EEFF] gap-y-[5px] text-sm font-pop py-5 sm:justify-between justify-center items-center sm:px-10 md:px-24 shadow-lg bottom-0 w-full">
      <p className="flex gap-2 items-center">
        Powered By
        <a href="https://altumindglobal.com/">
          <img src={logo} alt="logo" className="w-[105px]" />
        </a>
      </p>
      <p>© {currentYear} CogniSmiles. All rights reserved.</p>
    </footer>
  );
};

export default Copyright;
